const ContainerSection = ({children}: any) => {
  return (
    <div className="mx-auto w-full max-w-7xl lg:px-8">
      <div className="relative px-4 sm:px-8 lg:px-12">
        <div className="mx-auto px-4 max-w-2xl lg:max-w-5xl">
          {children}
        </div>
      </div>
    </div>
  );
};

export default ContainerSection;