import clsx from "clsx";
import ThemeToggle from "../ThemeToggle/ThemeToggle";
import avatarImage from'../../images/profile.jpg';

const AvatarContainer = ({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'div'>) => {
  return (
    <div
      className={clsx(
        className,
        'h-10 w-10 rounded-full bg-white/90 p-0.5 shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur dark:bg-zinc-800/90 dark:ring-white/10',
      )}
      {...props}
    />
  )
};

const Avatar = ({
  large = false,
}) => {
  return (
      <img
        src={avatarImage}
        alt=""
        sizes={large ? '4rem' : '2.25rem'}
        className={clsx(
          'rounded-full bg-zinc-100 object-cover dark:bg-zinc-800',
          large ? 'h-16 w-16' : 'h-9 w-9',
        )}
      />
  )
}

const Header = () => {
  return (
    <div className="relative flex gap-4 mt-6">
      <div className="flex flex-1">
        <AvatarContainer>
          <Avatar />
        </AvatarContainer>
      </div>
      <div className="flex flex-1 justify-end md:justify-center">
      </div>
      <div className="flex justify-end md:flex-1">
        <div className="pointer-events-auto">
          <ThemeToggle />
        </div>
      </div>
    </div>
  );
}

export default Header;