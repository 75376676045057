import { formatDate } from "../../lib/formatDate";
import { Card } from "../Card/Card";
import react from '../../images/logos/react.png';
import cypress from '../../images/logos/cypress.jpg';
import jest from '../../images/logos/jest.jpg';
import git from '../../images/logos/git.png';
import node from '../../images/logos/node.png';
import css from '../../images/logos/css.png';
import html from '../../images/logos/html.png';
import aws from '../../images/logos/aws.png';
import clsx from "clsx";


const Article = ({ article }: { article: any }) => {
  return (
    <Card as="article">
      <Card.Title>
        {article.title}
      </Card.Title>
      <Card.Eyebrow as="time" dateTime={article.date} decorate>
        {formatDate(article.startDate)} - {article.endDate ? formatDate(article.endDate): 'Present'}
      </Card.Eyebrow>
      {article.resumePoint.map((resumePoint: any, index: number) => {
        return (
          <Card.Description key={index}>{resumePoint}</Card.Description>
        );
      })}
    </Card>
  )
}

const AvatarContainer = ({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'div'>) => {
  return (
    <div
      className={clsx(
        className,
        'h-10 w-10 rounded-full bg-white/90 p-0.5 shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur dark:bg-zinc-800/90 dark:ring-white/10',
      )}
      {...props}
    />
  )
};

const Avatar = ({
  avatarSrc
}: any) => {
  return (
      <img
        src={avatarSrc}
        alt=""
        sizes="2.25rem"
        className={clsx(
          'rounded-full bg-zinc-100 object-cover dark:bg-zinc-800',
          'h-9 w-9',
        )}
      />
  )
}

function UsedSoftware({ software }: any) {
  return (
    <li className="flex gap-4">
      <AvatarContainer>
          <Avatar avatarSrc={software.logo}/>
        </AvatarContainer>
      <dl className="flex flex-auto flex-wrap gap-x-2">
        <dt className="sr-only">Company</dt>
        <dd className="w-full flex items-center text-sm font-medium text-zinc-900 dark:text-zinc-100">
          {software.company}
        </dd>
        
      </dl>
    </li>
  )
}

function BriefcaseIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      aria-hidden="true"
      {...props}
    >
      <path
        d="M2.75 9.75a3 3 0 0 1 3-3h12.5a3 3 0 0 1 3 3v8.5a3 3 0 0 1-3 3H5.75a3 3 0 0 1-3-3v-8.5Z"
        className="fill-zinc-100 stroke-zinc-400 dark:fill-zinc-100/10 dark:stroke-zinc-500"
      />
      <path
        d="M3 14.25h6.249c.484 0 .952-.002 1.316.319l.777.682a.996.996 0 0 0 1.316 0l.777-.682c.364-.32.832-.319 1.316-.319H21M8.75 6.5V4.75a2 2 0 0 1 2-2h2.5a2 2 0 0 1 2 2V6.5"
        className="stroke-zinc-400 dark:stroke-zinc-500"
      />
    </svg>
  )
}

function Resume() {
  let resume: Array<any> = [
    {
      company: 'React',
      logo: react
    },
    {
      company: 'Javascript / Node.js',
      logo: node
    },
    {
      company: 'HTML',
      logo: html
    },
    {
      company: 'CSS',
      logo: css
    },
    {
      company: 'Cypress',
      logo: cypress
    },
    {
      company: 'Jest',
      logo: jest
    },
    {
      company: 'Git',
      logo: git
    },
    {
      company: 'AWS Cloud Practitioner',
      logo: aws
    },
  ]

  return (
    <div className="rounded-2xl border border-zinc-100 p-6 dark:border-zinc-700/40">
      <h2 className="flex text-sm font-semibold text-zinc-900 dark:text-zinc-100">
        <BriefcaseIcon className="h-6 w-6 flex-none" />
        <span className="ml-3">What I specialize in</span>
      </h2>
      <ol className="mt-6 space-y-4">
        {resume.map((software, roleIndex) => (
          <UsedSoftware key={roleIndex} software={software} />
        ))}
      </ol>
    </div>
  )
}

const articleList = [
  {
    title: "JP Morgan Chase & Co",
    startDate: "2022-03",
    role: "Software Engineer",
    resumePoint: [
      "Migrated multiple projects to an updated tech stack including React and Jest. ",
      "A leader in adopting the Component Driven Development methodology where components can be tested individually and reused across projects. Used tools like Cypress to test components exactly how they would behave in production."
    ],
    slug: "article1"
  },
  {
    title: "Chepri LLC",
    startDate: "2019-05",
    endDate: "2022-03",
    role: "Software Engineer",
    resumePoint: [
      "Involved in planning and developing multiple enterprise web and mobile apps that reach hundreds of thousands of users.",
      "Developed fully customized solutions for unique business problems including in-store kiosk systems, kitchen display systems, and a custom capacity manager to throttle online orders.",
      "Optimized multiple web server configurations as well as lead 4 server and database migrations to Oracle Cloud. Created and managed server side NodeJS applications."
    ],
    slug: "article2"
  },
  {
    title: "Miami University",
    startDate: "2015-08",
    endDate: "2019-04",
    role: "Software Engineer",
    resumePoint: [
      "Interactive Media Studies with a focus in Web Development."
    ],
    slug: "article3"
  }
]

const Experience = () => {
  return (
    <>
      <div className="mt-16 sm:mt-32">
          <div className="lg:order-first lg:row-span-2 max-w-2xl">
            <h1 className="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
              My Experience
            </h1>
          </div>
      </div>
      <div className="mt-10 mx-auto grid max-w-xl grid-cols-1 gap-y-20 lg:max-w-none lg:grid-cols-2">
          <div className="flex flex-col gap-16">
            {articleList.map((article) => (
              <Article key={article.slug} article={article} />
            ))}
          </div>
          <div className="space-y-10 lg:pl-16 xl:pl-24">
            <Resume />
          </div>
        </div>
    </>
  );
}

export default Experience;