import { createContext, useContext, useEffect, useState } from "react";
import LoadingScreen from "../components/LoadingScreen/LoadingScreen";

interface ThemeContextData {
  theme: string | undefined;
  setTheme: any;
}

const ThemeContext = createContext<ThemeContextData>({
  theme: 'dark',
  setTheme: () => {}
});

const setThemeClasses = (theme: string) => {
  if (theme === 'dark') {
    document.body.classList.add('dark');
    document.body.classList.remove('bg-zinc-50');
    document.body.classList.add('bg-black');
  } else {
    document.body.classList.remove('dark');
    document.body.classList.remove('bg-black');
    document.body.classList.add('bg-zinc-50');
  }
  localStorage.setItem('theme', theme);
}

export const ThemeProvider = ({children}: any) => {
  
  const [ theme, setTheme ] = useState<undefined | string>();

  useEffect(() => {
    const storageTheme = localStorage.getItem("theme");
    if (storageTheme && (storageTheme === 'light' || storageTheme === 'dark')) {
      setThemeClasses(storageTheme);
      setTheme(storageTheme);
    } else {
      setThemeClasses('dark');
      setTheme('dark');
    }
  }, [])

  useEffect(() => {
    if (theme) {
      setThemeClasses(theme);
    };
  }, [theme]);

  if (!theme) {
    return (<LoadingScreen />);
  }

  return <ThemeContext.Provider value={{ theme, setTheme }}>{children}</ThemeContext.Provider>
}

export const useTheme = () => useContext(ThemeContext);