const Footer = () => {
  return (
    <footer className="mt-32 flex-none">
      <div className="border-t border-zinc-100 pb-16 pt-10 dark:border-zinc-700/40">
        <div className="flex flex-col items-center justify-between gap-6 sm:flex-row">
          <p className="text-sm text-zinc-400 dark:text-zinc-500">
            &copy; {new Date().getFullYear()} Daniel Dodson. All rights
            reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;