import './App.css';
import About from './components/About/About';
import ContainerSection from './components/Container/Container';
import Experience from './components/Experience/Experience';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Photos from './components/Photos/Photos';
import { ThemeProvider } from './providers/ThemeProvider';

const App = () => {

  return (
    <ThemeProvider>
      <div className="h-full antialiased">
        <div className="flex h-full bg-zinc-50 dark:bg-black">
            <div className="flex w-full">
              <div className="fixed inset-0 flex justify-center sm:px-8">
                <div className="flex w-full max-w-7xl lg:px-8">
                  <div className="w-full bg-white ring-1 ring-zinc-100 dark:bg-zinc-900 dark:ring-zinc-300/20" />
                </div>
              </div>
              <div className="relative flex w-full flex-col">
                <div className="flex-auto">
                  <ContainerSection>
                    <Header />
                    <About />
                  </ContainerSection>
                  <Photos />
                  <ContainerSection>
                    <Experience />
                    <Footer />
                  </ContainerSection>
                </div>
              </div>
            </div>
          </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
